.size-50 {
  width: 50px !important;
  height: 50px !important;
}

.text-link {
  color: #389583 !important;
  cursor: pointer;
}

.text-link:hover {
  color: #2a7062;
}

.nav-icon {
  padding-bottom: 8px;
  font-size: 28px !important;
}

.fab43 {
  cursor: pointer;
}

.fab43-default {
  cursor: pointer;
}

.toggler label div {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;
  width: 100%;
}

.toggler .toggler-priority {
  width: calc(25%) !important;
}

.toggler label {
  padding: unset !important;
}

.product-check-icon {
  position: absolute;
  left: 40px;
  bottom: 10px;
  color: #5cdb94;
  font-size: x-large;
}

.card-body {
  flex: 1 1 auto;
  padding: 5px 10px;
}

.card-body-item {
  color: #05396b !important;
  font-family: 'Sirvez Medium' !important;
  font-size: 16px !important;
}

.form-outline .form-control:valid~.form-label {
  transform: translateY(-1rem) translateY(0.1rem) scale(0.8);
}

.form-outline .form-control:valid~.form-notch .form-notch-middle {
  border-top: none;
  border-right: none;
  border-left: none;
  transition: all 0.2s linear;
}

.text-red {
  color: red !important;
}

.inline-middle {
  display: flex;
  align-items: center;
}

.room-figure {
  position: relative;
}

.room-img-actions {
  position: absolute;
  top: 6px;
  right: 6px;
}

.scan-product-img {
  width: 4.2rem;
  height: auto;
  color: dimgrey;
  margin-right: 0.8rem;
}

figure.add-new-img1 {
  background-color: #5cdb94;
}

figure.add-new-img1:hover {
  opacity: 0.7;
}

.font-1 {
  font-size: 0.8rem !important;
}

.font-2 {
  font-size: 0.9rem !important;
}

.font-3 {
  font-size: 1rem !important;
}

.font-4 {
  font-size: 1.1rem !important;
}

.font-5 {
  font-size: 1.2rem !important;
}

.font-6 {
  font-size: 1.3rem !important;
}

.font-7 {
  font-size: 1.4rem !important;
}

.font-8 {
  font-size: 1.5rem !important;
}

.font-9 {
  font-size: 1.6rem !important;
}

.font-10 {
  font-size: 1.8rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-box-content {
  padding: 16px 24px;
}

.spinner-loading {
  width: 1.2rem;
  height: 1.2rem;
  vertical-align: -0.125rem;
  border: 0.15em solid;
  border-right: 0.15em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.size-small {
  width: 1.2rem;
  height: 1.2rem;
}

.size-normal {
  width: 1.8rem;
  height: 1.8rem;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-avatar-badge {
  position: absolute;
  width: 12px;
  height: 12px;
  top: -2px;
  left: 23px;
  border: 2px solid white;
}

.size-10 {
  width: 10px !important;
  height: 10px !important;
}

.size-20 {
  width: 20px !important;
  height: 20px !important;
}

.size-30 {
  width: 30px !important;
  height: 30px !important;
}

.size-40 {
  width: 40px !important;
  height: 40px !important;
}

.size-50 {
  width: 50px !important;
  height: 50px !important;
}

.size-60 {
  width: 60px !important;
  height: 60px !important;
}

.size-70 {
  width: 70px !important;
  height: 70px !important;
}

.bg-amber {
  background-color: #ffc107 !important;
}

.bg-red {
  background-color: #ff0000 !important;
}

.border-2 {
  border: 2px solid #ced4da !important
}

.border-green {
  border-color: #4CAF50 !important;
}

.badge1 {
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  margin-bottom: 6px;
  border-radius: 0.125rem;
}

.chat-main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.bubble-message {
  position: relative;
  overflow: visible;
  background-color: transparent;
  flex-grow: 0;
  flex-shrink: 0;
  width: 30px;
  height: 15px;
  top: 1px;
  opacity: 0.8;
  margin-left: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("/light.gif");
  background-size: contain;
  border-style: none;
  display: flex;
}

.chat-main-footer {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-top: 1px solid #e9ecef;
}

.chat-textarea {
  height: 40px;
  box-shadow: none;
  border-radius: 0;
}

#pixieContainer {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.edit-point:hover {
  /* background-color: rgba(0, 0, 0, 0.04); */
  opacity: 0.6;
}

.text-green {
  color: #389583;
}

.testing-file {
  height: 80px;
  width: auto;
}

.bg-purple {
  background-color: #05396b !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 600px !important;
  }
}

.avatar-checkbox .MuiIconButton-label {
  width: 20px;
  height: 20px;
  background-color: white
}

.badge.badge-light-warning {
  color: #ff9f43 !important;
  background-color: rgba(255, 159, 67, 0.12);
}

.badge.badge-light-success {
  color: #28c76f !important;
  background-color: rgba(40, 199, 111, 0.12);
}

.badge.badge-light-black {
  color: #82868b !important;
  background-color: rgba(130, 134, 139, 0.12);
}

.badge.badge-light-danger {
  color: #ea5455 !important;
  background-color: rgba(234, 84, 85, 0.12);
}

.badge.badge-light-purple {
  color: #7367f0 !important;
  background-color: rgba(115, 103, 240, 0.12);
}

.remove-icon {
  width: 26px;
  height: 26px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 17px !important;
  background-color: white;
}

.fab28 {
  background-color: #5cdb94;
  width: 28px;
  height: 28px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 28px;
  box-shadow: 6px 2px 6px rgb(0 0 0 / 14%);
}

.location-list>div {
  background-color: #9be2b7;
  border-radius: 6px;
}

.cancel-btn {
  font-size: 1.2rem !important;
  cursor: pointer;
}

.text-purple {
  color: #05396b !important
}

.refresh-icon-btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
}

.modal-content .modal-title {
  width: 100%;
}