/* Generated by Glyphter (http://www.glyphter.com) on  Tue Jan 11 2022*/
@font-face {
    font-family: 'sirvez more';
    src: url('sirvez-2.eot');
    src: url('sirvez-2.eot?#iefix') format('embedded-opentype'),
         url('sirvez-2.woff') format('woff'),
         url('sirvez-2.ttf') format('truetype'),
         url('sirvez-2.svg#sirvez-2') format('svg');
    font-weight: normal;
    font-style: normal;
}
.siz{
    display:flex;
    align-items:center;
    justify-content:center;
}
[class*='siz-icon-']:before{
	display: inline-block;
   font-family: 'sirvez more';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.siz-icon-sun:before{content:'\0041';}
.siz-icon-order:before{content:'\0042';}
.siz-icon-bucket:before{content:'\0043';}
.siz-icon-module:before{content:'\0044';}
.siz-icon-check:before{content:'\0045';}
.siz-icon-tag-remove:before{content:'\0046';}
.siz-icon-crown:before{content:'\0047';}
.siz-icon-flag:before{content:'\0048';}

.siz-icon-add-task-workflow:before{content:'\0049';}

.siz-icon-arrow-up:before{content:'\004a'; transform:rotate(90deg);}
.siz-icon-location-survey:before{content:'\004b';}
.siz-icon-confirm:before{content:'\004c';}
.siz-icon-stop:before{content:'\004d';}
.siz-icon-child-customer:before{content:'\004e';}
.siz-icon-scan:before{content:'\004f';}
.siz-icon-signin1:before{content:'\0050';}
.siz-icon-scan:before{content:'\0055';}
.siz-icon-floor:before{content:'\0051';}
.siz-icon-reorder:before{content:'\0052';}
.siz-icon-video:before{content:'\0053';}