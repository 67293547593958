@import 'fonts.css';
@import 'zmdi-font.css';
/*Other Colors*/
/*Activity Colours*/
/*Locations*/
/*Helpdesk colors */
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.m-4 {
  margin: 1.5rem !important;
}
.ml-2 {
  margin-left: 0.6em !important;
}
.ml-3 {
  margin-left: 1em !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 2rem !important;
}
.mt-5,
.my-5 {
  margin-top: 2rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}
.m-6 {
  margin: 2.5rem !important;
}
.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}
.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}
.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}
.m-7 {
  margin: 3rem !important;
}
.mt-7,
.my-7 {
  margin-top: 3rem !important;
}
.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}
.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}
.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}
.m-8 {
  margin: 3.5rem !important;
}
.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}
.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}
.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}
.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}
.m-9 {
  margin: 4rem !important;
}
.mt-9,
.my-9 {
  margin-top: 4rem !important;
}
.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}
.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}
.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}
.m-10 {
  margin: 4.5rem !important;
}
.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}
.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}
.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}
.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}
.m-11 {
  margin: 5rem !important;
}
.mt-11,
.my-11 {
  margin-top: 5rem !important;
}
.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}
.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}
.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}
.ml-12,
.mx-12 {
  margin-left: 6rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 2rem !important;
}
.pt-5,
.py-5 {
  padding-top: 2rem !important;
}
.pr-5,
.px-5 {
  padding-right: 2rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}
.pl-5,
.px-5 {
  padding-left: 2rem !important;
}
.p-6 {
  padding: 2.5rem !important;
}
.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}
.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}
.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}
.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}
.p-7 {
  padding: 3rem !important;
}
.pt-7,
.py-7 {
  padding-top: 3rem !important;
}
.pr-7,
.px-7 {
  padding-right: 3rem !important;
}
.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}
.pl-7,
.px-7 {
  padding-left: 3rem !important;
}
.p-8 {
  padding: 3.5rem !important;
}
.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}
.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}
.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}
.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}
.p-9 {
  padding: 4rem !important;
}
.pt-9,
.py-9 {
  padding-top: 4rem !important;
}
.pr-9,
.px-9 {
  padding-right: 4rem !important;
}
.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}
.pl-9,
.px-9 {
  padding-left: 4rem !important;
}
.p-10 {
  padding: 4.5rem !important;
}
.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}
.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}
.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}
.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}
.p-11 {
  padding: 5rem !important;
}
.pt-11,
.py-11 {
  padding-top: 5rem !important;
}
.pr-11,
.px-11 {
  padding-right: 5rem !important;
}
.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}
.pl-11,
.px-11 {
  padding-left: 5rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
body {
  font-family: 'Sirvez Regular', 'Roboto', 'Helvetica Neue'!important;
  background: #f8f8f8 url(../images/bodybg.jpg) 0 0 no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
  width: 100%;
  color: #1f2732;
  font-size: 16px;
}
/**
 * Body CSS
 */
html,
body {
  height: 100%;
}
html,
body,
input,
textarea,
button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
a {
  text-decoration: none;
  color: #389583;
}
a:hover {
  text-decoration: none;
  color: #2a7062;
}
a:focus {
  color: #2a7062;
  text-decoration: none;
}
/**
  * Layout CSS
  */
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #fbfbfc;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9dce2;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b8f8d1;
}
#header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 12;
  transition: left 0.2s;
}
#header td {
  vertical-align: middle;
}
.mui-appbar {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.14);
}
#sidedrawer {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 240px;
  left: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 12;
  background-color: #1f2732;
  transition: all 0.2s;
}
#content-wrapper {
  min-height: 100%;
  overflow-x: hidden;
  margin-left: 0px;
  transition: margin-left 0.2s;
  position: relative;
  /* sticky bottom */
  margin-bottom: -160px;
  padding-bottom: 160px;
}
#footer {
  height: 60px;
  margin-left: 0px;
  transition: margin-left 0.2s;
  position: fixed;
  bottom: 0;
  width: 100%;
}
@media (min-width: 768px) {
  #header {
    left: 240px;
  }
  #sidedrawer {
    /* transform: translate(200px);*/
    width: calc(240px);
  }
  #content-wrapper {
    margin-left: 240px;
  }
  #footer {
    margin-left: 240px;
  }
  body.hide-sidedrawer #header {
    left: 75px;
  }
  body.hide-sidedrawer #project-summary {
    margin-left: 75px;
  }
  body.hide-sidedrawer #sidedrawer {
    /* transform: translate(0px);*/
    width: calc(75px);
  }
  body.hide-sidedrawer #content-wrapper {
    margin-left: 75px;
  }
  body.hide-sidedrawer #sidedrawer-brand {
    transform: translateX(-163px);
  }
  #sidedrawer-brand {
    transform: translateX(0px);
    transition: all 0.2s;
  }
  body.hide-sidedrawer #footer {
    margin-left: 75px;
  }
}
/**
  * Toggle Side drawer
  */
#sidedrawer.active {
  /*transform: translate(200px);*/
  width: calc(240px);
}
.mui-sidedrawer-inner {
  position: relative;
  top: 0;
  left: 0;
  width: 240px;
}
/**
  * Header CSS
  */
.sidedrawer-toggle {
  color: #ffffff;
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
  margin-top: 18px;
}
.sidedrawer-toggle:hover {
  color: #389583;
  text-decoration: none;
}
.d-flex {
  display: flex;
}
.d-column {
  flex-direction: column;
}
/**
  * Footer CSS
  */
#footer {
  background-color: #ffffff;
  color: #1f2732;
}
#footer a {
  color: #fff;
  text-decoration: underline;
}
/**
 * Side drawer CSS
 */
#sidedrawer-brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1.6em 1.6em;
}
#sidedrawer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#sidedrawer ul li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.06);
  min-height: 44px;
}
#sidedrawer > ul {
  padding-left: 0px;
}
#sidedrawer span + ul {
  background: #3d4653;
  padding: 0px;
  margin: 0px;
}
#sidedrawer span + ul li {
  display: flex;
  align-items: center;
}
#sidedrawer span + ul li a {
  font-size: 15px;
  color: #ffffff;
  font-family: 'Sirvez Regular';
  font-weight: 500;
  line-height: 21px;
  margin-left: 36px;
  display: flex;
}
#sidedrawer span + ul li:hover {
  background: #373f4a;
}
#sidedrawer > ul > li:first-child {
  border-top: solid 1px rgba(255, 255, 255, 0.06);
}
#sidedrawer ul span {
  display: flex;
  align-items: center;
  padding: 15px 22px;
  cursor: pointer;
  font-size: 15px;
  color: #ffffff;
  font-family: 'Sirvez Regular';
  font-weight: 500;
  line-height: 21px;
  transition: all 0.2s;
}
#sidedrawer ul span.active {
  background: #3d4653;
}
#sidedrawer ul i {
  padding-right: 1.6em;
  font-size: 21px;
}
#sidedrawer span:hover {
  background-color: #293342;
}
#sidedrawer span + ul > li {
  padding: 6px 0px;
}
.brandbg {
  background: #1f2732;
}
.date {
  font-size: 14px;
  color: #889caf;
}
.avatar-with-badge {
  margin-right: 1em;
  position: relative;
}
.avatar-with-badge .badge {
  width: 24px;
  height: 24px;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  right: -10px;
  min-width: unset;
}
.avatar-with-badge .circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: -2px;
  min-width: unset;
}
.avatar {
  border-radius: 50%;
  width: 43px;
  height: 43px;
  overflow: hidden;
  position: relative;
}
.avatar img {
  object-fit: cover;
}
header table {
  width: 100%;
}
.appbar-right {
  display: flex;
  align-items: center;
}
#topmenu {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
#topmenu .dropdown a {
  display: inline-flex;
  padding: 10px;
}
#topmenu .dropdown a.show {
  background: #f9fcf5;
  border-radius: 50%;
}
#topmenu li {
  /*  margin-left:0.8em;
        margin-right:0.7em;*/
}
#topmenu i {
  font-size: 24px;
  color: #05396b;
}
#topmenu .activity {
  padding: 10px;
  border-bottom: solid 1px #d9dce2;
}
#topmenu .activity:hover {
  background: #f8fcf1;
}
#topmenu .activity i {
  font-size: 14px;
  color: #ffffff;
}
h1 {
  color: #ffffff;
}
#main {
  margin-top: 80px;
  position: relative;
  z-index: 2;
  font-size: 16px;
}
.appbar-left {
  display: inline-flex;
  align-items: center;
}
.fab-absolute {
  right: 10px;
  top: 5px;
  position: absolute;
}
.fab43 {
  background: #5cdb94;
  box-shadow: 6px 2px 6px rgba(0, 0, 0, 0.14);
  width: 43px;
  height: 43px;
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 43px;
}
.fab43:hover {
  color: #389583;
  background: #86e4af;
}
.fab43 i.sir {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.fab33 {
  background: #5cdb94;
  box-shadow: 6px 2px 6px rgba(0, 0, 0, 0.14);
  width: 33px;
  height: 33px;
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 33px;
}

.fab33:hover {
  color: #389583;
  background: #86e4af;
}

.fab33 i.sir {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.fab43-default {
  background: #ffffff;
  font-size: 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  width: 43px;
  height: 43px;
  display: flex;
  color: #05396b;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 43px;
}
.fab43-default:hover {
  color: #389583;
  background: #f9fcf5;
}
.fab43-default i {
  font-size: 13px;
}
i.icon-small {
  font-size: 16px !important;
}
i.icon-large {
  font-size: 24px !important;
}
i.icon-medium {
  font-size: 20px !important;
}
.mui-panel {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}
.mui-panel-transparent {
  background: transparent !important;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: none;
}
#project-summary {
  position: fixed;
  margin-left: 240px;
  height: 100%;
  z-index: 4;
  width: 320px;
  top: 60px;
  left: -320px;
  transition: all 0.35s ease-in-out, left 0.35s ease-in-out;
  overflow-y: auto;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
}
#project-summary.active {
  left: 0;
}
.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  font-weight: 600;
  /*text-transform: uppercase;*/
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.4rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-size: 10px;
  /*box-shadow: 0 0 0.375rem 0 rgba(53, 64, 82, 0.5);*/
  line-height: 16px;
  min-width: 50px;
  background: #d7e6dd;
  color: #05396b;
}
.badge i {
  font-size: 16px;
}
.badge-white {
  background: #ffffff;
  color: #05396b;
}
.badge-status {
  flex: 0 0 auto;
  background: #d7e6dd;
}
.circle {
  width: 16px;
  height: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: unset;
}
.badge-circle {
  width: 43px;
  height: 43px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: unset;
}
.badge-circle-mini {
  width: 23px;
  height: 23px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: unset;
}
.numberbg {
  background: #644d75;
}
.tagbg {
  background: #d7e6dd;
}
.criticalbg {
  background: #c51c1c;
  color: #ffffff;
}
.criticalbg.half-opacity {
  color: #05396b;
  background: #f5b8b8;
}
.text-critical {
  color: #c51c1c;
}
.text-high {
  color: #ab3a7a;
}
.text-normal {
  color: #5cdb94;
}
.text-medium {
  color: #ffa927;
}
.highbg {
  background: #ab3a7a;
  color: #ffffff;
}
.highbg.half-opacity {
  color: #05396b;
  background: #ebc6db;
}
.bluebg {
  background: #1ab4d7;
}
.whitebg {
  background: #ffffff;
}
.text-white {
  color: #ffffff;
}
.mediumbg {
  background: #ffa927;
  color: #ffffff;
}
.mediumbg.half-opacity {
  color: #05396b;
  background: #fffaf3;
}
.normalbg {
  background: #347451;
  color: #ffffff;
}
.normalbg.half-opacity {
  color: #05396b;
  background: #ffffff;
}
.secondarybg {
  background: #389583;
  color: #ffffff;
}
.projectbg {
  background: #5cdb94;
  color: #ffffff;
}
.boardbg {
  background: #22696e;
  color: #ffffff;
}
.workflowbg {
  background: #8de4de;
  color: #05396b;
}
.taskbg {
  background: #ff6b4d;
  color: #ffffff;
}
.locationbg {
  color: #ffffff;
  background: #933ca2;
}
.userbg {
  color: #ffffff;
  background: #a5c472;
}
.checkinbg {
  color: #ffffff;
  background: #389583;
}
.productbg {
  color: #ffffff;
  background: #bc9165;
}
.signoffbg {
  color: #ffffff;
  background: #c51c1c;
}
.summary-toggle {
  z-index: 20;
  font-size: 16px;
  transition: all 0.2s;
}
.summary-toggle.active {
  transform: rotate(180deg);
}
h5 {
  color: #05396b;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
h3 {
  font-size: 28px;
  color: #1f2732;
  font-family: 'Sirvez Medium';
}
h2 {
  color: #1f2732;
  font-family: 'Sirvez Bold';
  font-size: 36px;
}
h4 {
  color: #1f2732;
  font-family: 'Sirvez Bold';
  font-size: 18px;
}
h5 + h3 {
  margin-top: 0;
  padding-top: 0;
}
/*Project Content*/
.levelbar {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  background: #fbfbfc;
  height: 12px;
  width: 90px;
  border-radius: 60px;
  margin-right: 5px;
}
.levelbar + .indicator {
  color: #889caf;
  font-size: 14px;
  display: inline-block;
}
.levelbar ul {
  display: inline-block;
  margin: 0px;
  padding: 0;
}
.levelbar ul li {
  position: relative;
  top: -10px;
  display: inline-block;
  border-right: solid 1px #ffffff;
  width: 30px;
  height: 12px;
  background: #389583;
}
.levelbar ul li:first-child {
  border-radius: 60px 0px 0px 60px;
}
.levelbar ul li:last-child {
  border-radius: 0px 60px 60px 0;
  border: none;
}
.feed-line {
  line-height: 18px;
  font-size: 15px;
}
.feed-line h4 {
  margin: 0;
  padding: 0;
}
.feed-line h5 {
  margin: 0;
  padding: 0;
}
.feed-line h6 {
  margin: 0;
  padding: 0;
}
.activity {
  flex-direction: row;
  flex-wrap: wrap;
}
.activity .activity-text {
  flex: 1;
}
.main-content {
  flex: 1;
}
.mui-panel {
  padding: 0;
}
.mui-panel-content {
  padding: 15px;
}
.custom-tabs {
  border-radius: 4px 4px 0 0;
  display: flex !important;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: space-between;
}
.custom-tabs > li {
  border-right: solid 1px #ebebeb;
  flex: 1 1 auto;
}
.custom-tabs > li > a {
  padding: 0.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Sirvez Bold';
  font-size: 13px;
  color: #05396b;
  cursor: pointer;
}
.custom-tabs > li > a i {
  font-size: 21px;
  margin-right: 0.5em;
  margin-left: 0.2em;
  margin-bottom: 2px;
}
.custom-tabs > li:last-child {
  border: none;
}
.mui-tabs__bar > li.mui--is-active {
  background: #ffffff;
  border-bottom: 2px solid #389583;
}
.mui-tabs__bar > li > a {
  color: #05396b !important;
}
.mui-tabs__bar > li.mui--is-active > a {
  color: #389583 !important;
}
.mui-panel-bottom {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: -2px;
  position: relative;
  transition: all 0.3s;
}
.mui-panel-top {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #e8fff0;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0.25rem 0.25rem 0 0;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: -2px;
  position: relative;
  transition: all 0.3s;
  overflow-x: auto;
}
.ribbon {
  color: #ffffff;
  width: 93px;
  height: 103px;
  overflow: hidden;
  position: absolute;
}
.ribbon h6 {
  color: #ffffff;
  padding: 0;
  margin: 0;
}
.ribbon h3 {
  color: #ffffff;
  padding: 0;
  margin: 0;
}
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
}
.ribbon span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 93px;
  padding: 15px 0;
  background-color: #5cdb94;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
}
/* top left*/
.ribbon-top-left {
  top: 10px;
  left: -10px;
}
.ribbon-top-left::after {
  width: 0px;
  height: 0px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #2a7062;
}
.ribbon-top-left::after {
  bottom: -5px;
  left: 0;
}
.ribbon-top-left span {
  top: -8px;
}
/* top right*/
.ribbon-top-right {
  top: 10px;
  right: -30px;
  width: 110px;
  height: 60px;
}
.ribbon-top-right i {
  font-size: 13px;
}
.ribbon-top-right::after {
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #2a7062;
}
.ribbon-top-right::after {
  bottom: 2px;
  right: 20px;
}
.mui-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  background: #ffffff;
  color: #05396b;
  height: 43px;
  font-family: 'Sirvez Bold';
}
.mui-btn i {
  padding: 0.2em;
  font-size: 21px;
}
.mui-btn-remove {
  display: inline-flex;
  margin-top: 0;
  line-height: unset;
  text-transform: unset;
  font-family: 'Sirvez Regular';
  padding: 3px;
  height: unset;
  justify-content: space-between;
  margin-bottom: 0;
}
.mui-btn-remove .avatar {
  width: 33px;
  height: 33px;
}
.mui-btn-remove .avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mui-btn-remove .activity-text {
  font-size: 16px;
  font-weight: bold;
}
.mui-btn-remove i {
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  border: solid 1.5px #05396b;
  width: 22px;
  height: 22px;
  font-size: 8px;
}
.mui-btn--primary {
  border-radius: 80px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  background: #5cdb94;
  color: #ffffff;
}
.mui-btn--primary:focus {
  background: #389583;
}
.mui-btn--primary:hover {
  background: #389583;
}
.mui-btn--primary i {
  color: #ffffff;
}
.inactive {
  color: rgba(5, 57, 107, 0.4);
}
.bs-stepper .step-trigger {
  flex-direction: column;
}
.bs-stepper-circle {
  border: solid 2px #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #d9dce2;
}
.bs-stepper-circle.completed {
  background: #5cdb94;
}
.bs-stepper-circle.current {
  background: #389583;
}
.bs-stepper .line,
.bs-stepper-line {
  background-color: transparent;
  height: 1px;
  border-top: dashed 1px #d9dce2;
}
.bs-stepper .step-trigger:hover {
  text-decoration: none;
  border: radius 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.09);
  background-color: #d9dce2;
  background-color: #f9fcf5;
}
.mui-panel-header {
  background: #e8fff0;
  padding: 8px 15px;
  border-radius: 4px 4px 0 0;
}
.mui-panel-header h5 {
  margin: 0;
  padding: 0;
}
.star-rating {
  margin: 0;
  padding: 0;
  list-style: none;
}
.star-rating li {
  padding: 2px;
}
.mui-tabs__bar li a {
  position: relative;
}
.notification {
  background: #ff6b4d;
  color: #ffffff;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 35px;
  border-radius: 50%;
}
.notification.secondarybg {
  background: #389583;
}
[class*='col-'] {
  position: relative;
}
[class*='col-'] .mui-panel {
  height: 100%;
  margin: 0;
  padding: 0;
}
.list-info {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
}
.list-info.icons-frame {
  font-size: 14px;
  display: grid;
  grid-template-columns: repeat(30px 1fr);
  column-gap: 5px;
}
.list-info.company li {
  font-size: 14px;
  justify-content: flex-start;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-count: 2;
  column-gap: 5px;
}
.list-info.company li span {
  grid-column: 0.5;
}
.list-info.company li span:first-child {
  margin-right: 10px;
}
.list-info li {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
}
.list-info li span {
  align-items: center;
}
.list-info li i {
  font-size: 21px;
  color: #389583;
}
.proposal-open {
  background: #fbfbfc;
  border-radius: 6px;
  padding: 15px;
}
.proposal-open .custom-table tr {
  background-color: #f8fcf1;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  min-height: 55px;
  justify-content: space-between;
}
.proposal-open .custom-table thead tr {
  justify-content: space-between;
  background: none;
  box-shadow: none;
}
.filter {
  background: #f5f5f8;
  border-radius: 6px;
}
.filter-dark {
  width: 100%;
  border-radius: 6px;
  display: flex;
  padding: 10px;
  background: rgba(1, 33, 64, 0.4);
  color: #ffffff;
  align-items: center;
  transition: all 0.2s;
  justify-content: center;
}
.filter-dark select {
  max-height: 43px;
  border: none;
  flex: 1;
}
.filter-dark .form-check-input {
  border: none !important;
}
.filter-pill {
  padding: 10px 18px;
  margin: 3px;
  border-radius: 80px;
  background: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #05396b;
  position: relative;
}
.filter-pill .circle {
  margin-right: 8px;
}
.filter-pill.selected {
  background: #5cdb94;
  color: #ffffff;
}
.custom-table th {
  border: unset;
  color: #ffffff;
  font-size: 13px;
  border-bottom: solid 0px transparent;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.custom-table th.maintd {
  max-width: 320px;
  position: relative;
}
.custom-table thead tr {
  justify-content: space-between;
  border: none;
  background: transparent;
  min-height: unset;
  border-radius: unset;
  box-shadow: unset;
}
.custom-table thead tr:hover {
  background: transparent;
  transform: none;
}
.custom-table tr {
  transition: all 0.4s;
  border: unset;
  min-height: 74px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.34);
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  align-items: center;
  justify-content: space-between;
}
.custom-table tr:hover {
  background: #f9fcf5;
}
.custom-table tr td {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.custom-table tr td.maintd {
  max-width: 320px;
  position: relative;
}
.custom-table tr td:first-child {
  flex: unset;
}
.custom-table tr td:last-child {
  flex: unset;
}
.custom-table .btn-add-favourite {
  display: inline-flex;
  font-size: 24px;
}
.mui-table.custom-table > thead > tr > th {
  border-bottom: solid 0px transparent;
  padding-bottom: 0;
  font-family: 'Sirvez Regular';
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
}
.mui-table.custom-table > thead > tr > th:first-child {
  flex: unset;
  text-align: left;
}
.mui-table.custom-table > thead > tr > th:last-child {
  flex: unset;
}
.table-status {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-status.yes {
  background: #5cdb94;
  color: #ffffff;
}
.table-status.no {
  background: #ffffff;
  color: #d9dce2;
  border: solid 1px #d9dce2;
}
.btn-add-favourite {
  color: #889caf;
}
.btn-icons a {
  margin-right: 2px;
  margin-left: 2px;
}
.tooltip div {
  background: #389583;
}
.actions {
  display: none;
}
.form-check-input[type='checkbox']:checked {
  background-color: #5cdb94;
}
.form-check-input[type='checkbox']:checked:focus {
  background-color: #5cdb94;
}
.form-check-input:checked {
  border-color: #5cdb94;
}
.form-check-input:checked:focus {
  border-color: #5cdb94;
}
th {
  position: relative;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  transform: translate(-50%, -50%);
}
.popover .card-body {
  padding: 5px 10px;
}
.popover.popover-right {
  left: unset;
  right: 0;
  transform: translate(0, -50%);
}
.popover ul {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 100px;
}
.popover ul li {
  margin-top: 15px;
  margin-bottom: 15px;
}
.popover ul li a {
  color: #05396b;
  font-family: 'Sirvez Medium';
  font-size: 16px;
}
.popover ul li a:hover {
  color: #389583;
}
.search {
  display: flex;
  flex: 1;
  position: relative;
}
.search .form-control {
  border-radius: 80px;
  border-left: solid 1px #d9dce2;
  border-right: solid 1px #d9dce2;
  border-top: solid 1px #d9dce2;
  border-bottom: solid 1px #d9dce2;
}
.search span {
  position: absolute;
  right: 15px;
  top: 10px;
  border: none !important;
  font-size: 21px;
}
.form-check-input[type='checkbox']:checked:after {
  margin-left: 0.35rem;
  margin-top: 0px;
}
.product-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  left: 0;
  display: block;
}
.form-check-input {
  border: 1px solid #d9dce2;
}
#individual-actions {
  display: none;
  opacity: 0;
  transition: all 0.4s;
}
#individual-actions.open {
  opacity: 1;
  display: flex !important;
}
@media (max-width: 1024px) {
  .custom-tabs > li > a i {
    margin-right: 0.2em;
  }
}
.timer {
  height: 30px;
  display: flex;
  border: solid 1px #05396b;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  padding: 0.2em;
  color: #05396b;
}
.timer.text-critical {
  color: #c51c1c;
}
.timer.text-high {
  color: #ab3a7a;
}
.timer.text-normal {
  color: #5cdb94;
}
.timer.text-medium {
  color: #ffa927;
}
label.file {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #889caf;
  border: 2px dashed #d9dce2;
  padding: 2rem;
}
label.file:hover {
  background-color: #f9fcf5;
}
label.file:active,
label.file.focus {
  border-color: #5cdb94;
}
input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: 0;
  border: 1px solid red;
}
.bs-stepper-header {
  margin-top: 1em;
}
.bs-stepper .step-trigger {
  padding: 0;
}
.bs-stepper .line,
.bs-stepper-line {
  position: relative;
  margin-top: 1.2em;
}
.activities {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.activities:before {
  content: '';
  width: 1px;
  border-left: dashed 1px #d9dce2;
  display: flex;
  flex: 1;
  position: absolute;
  left: 21px;
  top: 22px;
  height: 80%;
  z-index: 0;
}
.list-item {
  transition: all 0.4s;
  border: unset;
  min-height: 43px;
  padding: 1em;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.34);
  display: flex;
  flex-wrap: nowrap;
  border-radius: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  align-items: center;
}
.list-item:hover {
  background: #f9fcf5;
}
.ml-auto {
  margin-left: auto;
}
.proposal-open thead th {
  color: #889caf;
  text-transform: none;
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}
.accordion-button {
  box-shadow: none !important;
  color: #05396b !important;
}
.accordion-item {
  border: none !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.34);
}
.d-relative {
  position: relative;
}
.border-bottom {
  padding-bottom: 0.3em;
  border-bottom: solid 1px #d9dce2;
}
#proposal-attachments {
  min-width: 250px;
  max-width: 400px;
  right: 0;
  top: -50%;
  left: unset;
}
.form-check {
  font-family: 'Sirvez Regular';
  display: flex;
}
.form-check .form-check-input[type='checkbox'] {
  flex: 0 0 18px;
}
.form-switch .form-check-input[type='checkbox'] {
  flex: unset !important;
}
.form-switch .form-check-input:checked[type='checkbox']:after {
  background-color: #389583;
}
legend {
  font-family: 'Sirvez Bold';
  font-size: 14px;
  text-transform: uppercase;
  color: #05396b;
}
.total-tr {
  background-color: #f4e7f6 !important;
}
.total-tr h3 {
  margin-bottom: 0;
}
.subtotal-tr {
  background-color: #dbf1ed !important;
}
.subtotal-tr h3 {
  margin-bottom: 0;
}
.total {
  padding: 15px;
  background: #fbfbfc;
}
.users {
  list-style: none;
  padding: 0;
  margin: 0;
}
.users li {
  position: relative;
  margin-left: -10px;
}
.users li:first-child {
  margin-left: 0;
}
tr {
  position: relative;
}
tr:after {
  content: '';
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 0px 0px 4px;
}
tr.critical:after {
  background: #c51c1c;
}
tr.high:after {
  background: #ab3a7a;
}
tr.medium:after {
  background: #ffa927;
}
tr.normal:after {
  background: #5cdb94;
}
.helpdesk-tasks li {
  position: relative;
}
.helpdesk-tasks li:after {
  content: '';
  width: 6px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 0px 0px 4px;
}
.helpdesk-tasks li.critical:after {
  background: #c51c1c;
}
.helpdesk-tasks li.high:after {
  background: #ab3a7a;
}
.helpdesk-tasks li.medium:after {
  background: #ffa927;
}
.helpdesk-tasks li.normal:after {
  background: #5cdb94;
}
.bs-stepper-label .date {
  margin-left: 0.5em;
}
/*App*/
.app-table {
  border-collapse: separate;
  border-spacing: 0 0.3em;
}
.app-table th {
  display: table-cell !important;
}
.app-table tr {
  background: transparent;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.38);
  display: table-row !important;
}
.app-table tr:hover td {
  background: #f9fcf5 !important;
}
.app-table td {
  transition: all 0.2s;
  display: table-cell !important;
  background: #ffffff;
}
.app-table td:first-child {
  border-radius: 6px 0 0 6px;
  text-align: center;
}
.app-table td {
  /* position: unset !important; */
  line-height: 16px;
}
.app-table td:last-child {
  border-radius: 0 6px 6px 0;
}
.app-table tr.add-new {
  box-shadow: none !important;
  background: transparent;
}
.app-table tr.add-new .form-outline .form-control ~ .form-notch .form-notch-middle {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(99%);
  height: 100%;
  border-right: none;
  border-left: none;
}
.app-table tr.add-new > td {
  color: #ffffff;
  border-top: dashed 1px #ffffff;
  border-bottom: dashed 1px #ffffff;
  box-shadow: none !important;
  background: transparent !important;
}
.app-table tr.add-new > td:hover td {
  background: transparent !important;
}
.app-table tr.add-new > td:last-child {
  border-right: dashed 1px #ffffff;
  border-bottom: dashed 1px #ffffff;
  border-top: dashed 1px #ffffff;
}
.app-table tr.add-new > td:first-child {
  border-left: dashed 1px #ffffff;
  border-bottom: dashed 1px #ffffff;
  border-top: dashed 1px #ffffff;
}
.app-table .accodion-open {
  display: flex !important;
  background: #fbfbfc;
  box-shadow: none;
  border-radius: 6px;
}
.app-table .accodion-open > td:last-child {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.app-table .delivery {
  width: 83px;
  height: 83px;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-table .delivery.incoming {
  background: #389583;
}
.app-table .delivery.outgoing {
  background: #644d75;
}
.app-table .workflow {
  border-radius: 6px;
}
.app-table .workflow a {
  color: #05396b;
}
.app-layout {
  width: 834px;
  max-width: 834px;
}
#bottommenu {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  left: 0px;
  height: 64px;
  width: 834px;
  left: 50%;
  margin-left: -417px;
  z-index: 999;
  background-color: #1f2732;
  transition: all 0.2s;
}
#bottommenu .bottommenu-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
#bottommenu .bottommenu-inner ul {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 0;
  padding: 0;
  flex: 1;
  width: 100%;
}
#bottommenu .bottommenu-inner ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex: 1;
}
#bottommenu .bottommenu-inner ul li.selected {
  background: #3d4653;
}
#bottommenu .bottommenu-inner ul li a {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 10px;
}
#bottommenu .bottommenu-inner ul li a i {
  padding-bottom: 8px;
  font-size: 28px !important;
}
#bottommenu .bottommenu-inner ul li:hover {
  background: #3d4653;
}
.app-header {
  left: 50% !important;
  margin-left: -417px !important;
  width: 834px;
}
.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.app-layout {
  margin-top: 0px;
  margin-left: unset;
  height: 1194px;
  overflow-y: auto;
}
.app-layout #content-wrapper {
  padding: unset;
  margin: unset;
  margin-bottom: 70px;
}
h3 {
  margin-bottom: unset;
}
/*Steps*/
.steps {
  margin: 0px;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.steps li {
  display: flex;
  flex: 1 1 24%;
  margin-left: 0;
  list-style-type: none;
  padding: 5px 5px 5px 30px;
  /* padding around text, last should include arrow width */
  text-align: center;
  height: 48px;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #fbfbfc;
}

.steps li:first-child {
  border-bottom-left-radius: 55px;
  border-top-left-radius: 55px;
}

.steps li:last-child {
  border-bottom-right-radius: 55px;
  border-top-right-radius: 55px;
}

.steps li:nth-child(1) a {
  padding-left: 0;
}
.steps li:not(:last-child)::after {
  content: '';
  z-index: 10;
  position: absolute;
  width: 0;
  height: 0;
  top: 7px;
  right: -16px;
  box-sizing: border-box;
  border: 17px solid black;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 50% 50%;
  transform: rotate(-135deg);
  border-radius: 0px 0px 0 5px;
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}
.steps a {
  color: #2f1f3e;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Current */
.steps li.current {
  background-color: #ffffff;
}
/* Following */
/* Hover for completed and current */
.steps li:hover {
  background-color: #c7ece8 !important;
  transition: all 0.3s;
}
.steps li:hover:after {
  border-color: transparent transparent #c7ece8 #c7ece8 !important;
  transition: all 0.3s;
}
.steps li.completed {
  background: #f1f2f2;
}
.steps li.completed a::before {
  position: relative;
  display: inline-block;
  font-size: 20px;
  top: 0px;
  left: 2px;
  margin-right: 5px;
  color: #389583;
}
.steps li.completed::after {
  border-color: transparent transparent #f1f2f2 #f1f2f2;
}
.steps li.current::before {
  position: absolute;
  /* content: url("../images/currentstep.svg"); */
  width: 37px;
  height: 37px;
  bottom: -26px;
}
.steps li.stage-current {
  background-color: #389583;
}
.steps li.stage-current::after {
  border-color: transparent transparent #389583 #389583;
}
.steps li.stage-current:hover {
  background-color: #46baa3 !important;
  transition: all 0.3s;
}
.steps li.stage-current:hover:after {
  border-color: transparent transparent #46baa3 #46baa3 !important;
  transition: all 0.3s;
}
.steps li.stage-current a {
  color: #ffffff !important;
}
/*Hourglass*/
@keyframes top-clip {
  50% {
    transform: translateY(21px);
  }
  100% {
    transform: translateY(21px);
  }
}
@keyframes bottom-sand-path {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes bottom-sand-g {
  85% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-9px);
  }
}
@keyframes hourglass-rotation {
  50% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(180deg);
  }
}
#top-sand #top-clip-path rect,
#bottom-sand path,
#bottom-sand g,
svg {
  animation-duration: 5s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}
#top-sand #top-clip-path rect {
  animation-name: top-clip;
}
#bottom-sand path {
  transform: translateY(21px);
  animation-name: bottom-sand-path;
}
#bottom-sand g {
  animation-name: bottom-sand-g;
}
.hourglass svg {
  animation-name: hourglass-rotation;
}
.hourglass {
  display: inline-flex;
}
.icon-stepper {
  display: inline-flex;
  font-size: 22px;
  margin-right: 0.4em;
}
/*End of Steps*/
.table-status-project ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.table-status-project ul li {
  position: relative;
  display: flex;
  z-index: 10;
}
.table-status-project ul:before {
  z-index: 0;
  position: absolute;
  content: '';
  width: 90%;
  height: 1px;
  left: 5px;
  right: 5px;
  border-top: dashed 1px #d9dce2;
}
.lightgreybg {
  background: #f9fbff !important;
}
.backlink {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.backlink i {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: inline-flex;
  margin-right: 0.2em;
  align-items: center;
  justify-content: center;
  border: solid 1px #ffffff;
}
.vertical-tabs {
  overflow-x: unset;
  display: flex;
  flex-direction: column;
}
.vertical-tabs li {
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.24);
  border: none !important;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 0.4em;
}
.vertical-tabs li a {
  border: none !important;
}
.vertical-tabs li.mui--is-active {
  background: #b8f8d1;
}
.vertical-tabs li:hover {
  background: #f8fcf1;
}
/*Locations Grid*/
.location-grid {
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
  column-count: 3;
  column-gap: 5px;
}
.location-grid h4 {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.location-grid > div {
  padding: 1em;
  border-radius: 8px;
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}
.location-grid > div:nth-child(3n+0) {
  background: #e2ecd1;
}
.location-grid > div:nth-child(3n+1) {
  background: #9be2b7;
}
.location-grid > div:nth-child(3n+2) {
  background: #e1e8f6;
}
.location1 {
  grid-row: 0.33333333;
}
.location2 {
  grid-column: 0.6;
}
.location3 {
  grid-row: 0.5;
}
.location4 {
  grid-column: 0.6;
}
.img-panel figure {
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}
.img-panel figure img {
  max-width: 100%;
  display: block;
  grid-row: -1;
  grid-column: 1;
}
.img-panel figure .buttons {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.2em 0.5em;
  justify-self: start;
}
.img-filter {
  column-count: 3;
  /* column-gap: 10px; */
}
.img-filter figure {
  width: 83px;
  height: 83px;
  border-radius: 8px;
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
}
.img-filter figure:hover {
  opacity: 0.8;
}
.img-filter figure img {
  grid-row: -1;
  grid-column: 1;
}
.img-filter figure.add-new-img {
  background: #5cdb94;
}
.img-filter figure.add-new-img i {
  color: #ffffff;
}
.notes {
  background: #f7fbf1;
  border-radius: 6px;
}
.appbar-left > ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.appbar-left > ul .avatar img {
  position: absolute;
}
.upload label.file {
  border-radius: 6px;
  padding: 0.2em;
}
.upload figure {
  width: 83px;
  height: 83px;
  border-radius: 8px;
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  break-inside: avoid;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
}
.upload figure:hover {
  opacity: 0.8;
}
.upload figure img {
  grid-row: -1;
  grid-column: 1;
}
.upload figure.add-new-img {
  background: #5cdb94;
}
.upload figure.add-new-img i {
  color: #ffffff;
}
.table-light thead th {
  color: #889caf;
  text-transform: none;
  justify-content: space-between;
}
.activities .activity {
  margin-top: 1em;
}
.delivery {
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.delivery.incoming {
  background: #389583;
}
.delivery.outgoing {
  background: #644d75;
}
.action-btn {
  position: relative;
}
.accordion-open {
  background: #fbfbfc;
  display: flex;
  border-radius: 6px;
  padding: 15px;
}
.accordion-open.collapse:not(.show) {
  display: none !important;
}
.bg-light-blue {
  background: #8de4de;
}
button {
  outline: none !important;
}
button.fab43-default {
  font-size: 21px;
  border: none;
}
button.fab43-default i {
  font-size: 21px;
}
.new-modal-lable-task {
  border-radius: 6px;
  background: #f9fcf5;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
.board {
  border-radius: 6px;
  background: #22696e;
  color: #ffffff;
}
.board i {
  display: inline-flex;
}
.board.rand2 {
  background: #22696e;
}
.board.rand3 {
  background: #2b8c94;
}
.board.rand4 {
  background: #33bea6;
}
.workflow-tasks {
  margin: 0;
  padding: 0;
}
.workflow-tasks > li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
}
.workflow-tasks > li.add-new {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18), 0 0 2px 0 rgba(0, 0, 0, 0.18);
  background: #ffffff;
}
.workflow-tasks > li.add-new .form-outline {
  flex: 1;
}
.workflow-tasks > li .bar-icon {
  padding: 10px;
  color: #889caf;
}
.workflow-tasks > li .bar-icon:hover {
  cursor: grab;
}
.workflow-tasks > li .task-number {
  flex-basis: 43px;
}
.workflow-tasks > li .task-number.inactive {
  opacity: 0.4;
}
.workflow-tasks > li div {
  display: inline-flex;
}
.workflow-tasks > li .board {
  max-width: 22%;
  padding: 5px;
  align-items: flex-start;
}
.workflow-tasks > li .board a {
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  left: 0;
  font-size: 15px;
  display: block;
}
div.users > div {
  display: flex;
  flex-direction: column;
}
.loading-icon {
  width: 30px;
  transform-origin: left center;
  transform: scale(0.8);
}
.form-control:focus ~ .form-notch div {
  border-color: #05396b !important;
}
.form-control:focus ~ .form-label {
  color: #05396b !important;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
.app-table .add-new .form-control ~ .form-notch div {
  border-color: #ffffff !important;
}
.app-table .add-new .form-control:focus ~ .form-notch div {
  border-color: #f8fcf1 !important;
}
.app-table .add-new .form-control ~ .form-label {
  color: #ffffff !important;
}
.app-table .add-new .form-control:focus ~ .form-label {
  color: #f8fcf1 !important;
}
.text-left {
  text-align: left !important;
}
#task-type.toggler > div {
  width: calc(25%);
}
#task-type.toggler label {
  padding: unset;
}
#task-type.toggler label div {
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.add-new {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #d9dce2 !important;
}
.vertical-tabs li {
  transition: all 0.3s ease;
}
.vertical-tabs li.add-new {
  color: #389583 !important;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 2px #d9dce2 !important;
  box-shadow: none;
  background: transparent;
  height: 43px;
}
.vertical-tabs li.add-new a {
  color: #389583 !important;
}
.vertical-tabs li.add-new:hover {
  background: #b8f8d1;
}
#datepicker {
  position: relative;
}
#datepicker:after {
  content: '\0032';
  z-index: 9999;
  position: absolute;
  right: 5px;
  top: 5px;
  display: block;
  width: 24px;
  height: 24px;
  font-weight: bold;
  font-family: 'sirvez';
}
.toggler {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.4);
  text-align: center;
  position: relative;
  border-radius: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.toggler > div {
  color: white;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: calc(50%);
  background-color: #5cdb94;
  border-radius: 80px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.4);
  z-index: 1;
  pointer-events: none;
  transform-origin: 50% 50%;
  transition: transform 0.3s;
}
.toggler::after {
  content: '';
  display: block;
  clear: both;
}
.toggler label {
  float: left;
  width: calc(49%);
  position: relative;
  padding: 10px 0px 40px;
  overflow: hidden;
  transition: color 0.3s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.toggler label:first-child {
  border-left: none;
}
.toggler label input {
  position: absolute;
  top: -200%;
}
.toggler label div {
  z-index: 5;
  position: absolute;
  width: 100%;
}
.toggler label.selected {
  color: white;
}
.company-logo {
  max-width: 80px;
  height: auto;
  position: relative;
}
.company-logo.large {
  max-width: 180px;
}
.company-logo img {
  object-fit: contain;
}
.items {
  margin: 0;
  padding: 0;
}
.items.icons-frame li {
  font-size: 14px;
  display: grid;
  grid-template-columns: 30px 1fr;
  column-gap: 5px;
}
.items.icons-frame li i {
  font-size: 24px;
  color: #389583;
}
.items > li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.uppercase {
  text-transform: uppercase;
}
h6 {
  margin-bottom: 0;
}
.border-bottom {
  padding-bottom: 1em;
  border-bottom: solid 1px #d9dce2;
}
.mui-panel-border {
  border: solid 1px #d9dce2;
  box-shadow: none;
}
.mui-panel-grey {
  border: solid 1px #d9dce2;
  background: #f9fbff;
  box-shadow: none;
}
.row {
  --bs-gutter-x: 0.5rem;
  --mdb-gutter-x: 0.5rem;
}
.mui-panel-service {
  background: #fbfdff;
}
.text-white + .search input {
  border: none;
}
.helpdesk-tasks {
  margin: 0;
  padding: 0;
}
.helpdesk-tasks > li {
  margin-bottom: 10px;
  padding-left: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 40px 0.5fr;
  padding-bottom: unset;
  align-items: center;
  column-gap: 5px;
  border-radius: 8px;
}
.helpdesk-tasks > li.add-new {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18), 0 0 2px 0 rgba(0, 0, 0, 0.18);
  background: #ffffff;
}
.helpdesk-tasks > li.add-new .form-outline {
  flex: 1;
}
.helpdesk-tasks > li .bar-icon {
  padding: 10px;
  color: #889caf;
}
.helpdesk-tasks > li .bar-icon:hover {
  cursor: grab;
}
.helpdesk-tasks > li .task-number {
  flex-basis: 43px;
}
.helpdesk-tasks > li .task-number.inactive {
  opacity: 0.4;
}
.helpdesk-tasks > li div {
  display: inline-flex;
  flex: 0 0 auto;
}
.helpdesk-tasks > li .board {
  max-width: 22%;
  padding: 5px;
  align-items: flex-start;
}
.helpdesk-tasks > li .board a {
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  left: 0;
  font-size: 15px;
  display: block;
}
.cat-text {
  color: #05396b;
}
.avatar-small {
  width: 33px;
  height: 33px;
}
.pending {
  background: #cfc9ef;
}
.inprogress {
  background: #c9dcef;
}
.new {
  background: #edf5e0;
}
.done td {
  background: #b8f8d1 !important;
}
#dropdownNotificationsMenu {
  padding-top: 20px;
  width: 20vw !important;
  height: 80vh !important;
  overflow: scroll-y !important;
}
tr.accordion-item + tr {
  box-shadow: none !important;
  min-height: 10px !important;
}
.action-btn i {
  display: flex;
  transition: all 0.3s;
}
.action-btn.active {
  background: #389583;
}
.action-btn.active i {
  color: #ffffff;
}
.action-btn.active i.fa-chevron-down {
  transform: rotate(-540deg);
}
.comments {
  list-style: none;
  padding: 10px;
  background: #f9fbff;
}
.comments .activity {
  align-items: center;
}
.comments .activity .activity-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
h5 {
  text-align: left;
}
td .tab-content {
  padding-top: 1em;
}
.buttons .nav {
  flex-wrap: unset;
}
