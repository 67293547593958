/* Generated by Glyphter (http://www.glyphter.com) on  Wed Jan 05 2022*/
@font-face {
    font-family: 'sirvez';
    src: url('sirvez.eot');
    src: url('sirvez.eot?#iefix') format('embedded-opentype'),
         url('sirvez.woff') format('woff'),
         url('sirvez.ttf') format('truetype'),
         url('sirvez.svg#sirvez') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='sir-icon-']:before{
	display: inline-flex;
   font-family: 'sirvez';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}

.camera-weight:before {
    font-weight: 600!important;
}

.sir{
    display:flex;
    align-items:center;
    justify-content:center;
}
.sir-icon-website:before{content:'\0041';}
.sir-icon-group:before{content:'\0042';}
.sir-icon-ungroup:before{content:'\0043';}
.sir-icon-check-in:before{content:'\0044';}
.sir-icon-import-to-project:before{content:'\0045';}
.sir-icon-remove-product:before{content:'\0046';}
.sir-icon-hide:before{content:'\0047';}
.sir-icon-texting-form:before{content:'\0048';}
.sir-icon-location-form:before{content:'\0049';}
.sir-icon-contact-person:before{content:'\004a';}
.sir-icon-clock:before{content:'\004b';}
.sir-icon-send-mail:before{content:'\004c';}
.sir-icon-download-pdf:before{content:'\004d';}
.sir-icon-search:before{content:'\004e';}
.sir-icon-export-template:before{content:'\004f';}
.sir-icon-import:before{content:'\0050';}
.sir-icon-export:before{content:'\0051';}
.sir-icon-arrow-right:before{content:'\0052';}
.sir-icon-arrow-left:before{content:'\0053';}
.sir-icon-camera:before{content:'\0054';}
.sir-icon-comment:before{content:'\0055';}
.sir-icon-pdf:before{content:'\0056';}
.sir-icon-filter:before{content:'\0057';}
.sir-icon-print:before{content:'\0058';}
.sir-icon-visit-log:before{content:'\0059';}
.sir-icon-ticket-callcentre:before{content:'\005a';}
.sir-icon-phone:before{content:'\0061';}
.sir-icon-ticket-form:before{content:'\0062';}
.sir-icon-user:before{content:'\0063';}
.sir-icon-plus:before{content:'\0064';}
.sir-icon-minus:before{content:'\0065';}
.sir-icon-menu-more:before{content:'\0066';}
.sir-icon-form:before{content:'\0067';}
.sir-icon-site:before{content:'\0068';}
.sir-icon-settings:before{content:'\0069';}
.sir-icon-notification:before{content:'\006a';}
.sir-icon-duplicate:before{content:'\006b';}
.sir-icon-watching:before{content:'\006c';}
.sir-icon-reminder:before{content:'\006d';}
.sir-icon-signed-off:before{content:'\006e';}
.sir-icon-circle-x:before{content:'\006f';}
.sir-icon-tick-circle:before{content:'\0070';}
.sir-icon-work-in-progress:before{content:'\0071';}
.sir-icon-attachment:before{content:'\0072';}
.sir-icon-remove:before{content:'\0073';}
.sir-icon-edit:before{content:'\0074';}
.sir-icon-back:before{content:'\0075';}
.sir-icon-form-created:before{content:'\0076';}
.sir-icon-delivery:before{content:'\0077';}
.sir-icon-supplier:before{content:'\0078';}
.sir-icon-manufacturer:before{content:'\0079';}
.sir-icon-product-library:before{content:'\007a';}
.sir-icon-my-tasks:before{content:'\0030';}
.sir-icon-contracts:before{content:'\0031';}
.sir-icon-task-calendar:before{content:'\0032';}
.sir-icon-workflow:before{content:'\0033';}
.sir-icon-board:before{content:'\0034';}
.sir-icon-helpdesk:before{content:'\0035';}
.sir-icon-service-desk:before{content:'\0036';}
.sir-icon-live-project:before{content:'\0037';}
.sir-icon-new:before{content:'\0038';}
.sir-icon-projects:before{content:'\0039';}
.sir-icon-customers:before{content:'\0021';}
.sir-icon-dashboard:before{content:'\0022';}
.sir-icon-tag:before{content:'\0023';}
.sir-icon-credit-card:before{content:'\0024';}
.sir-icon-company:before{content:'\0025';}
.sir-icon-date:before{content:'\0026';}
.sir-icon-unknown:before{content:'\0027';}
.sir-icon-star-empty:before{content:'\0028';}
.sir-icon-star:before{content:'\0029';}
.sir-icon-more-actions:before{content:'\002a';}
.sir-icon-sign-off:before{content:'\002b';}
.sir-icon-product:before{content:'\002c';}
.sir-icon-location:before{content:'\002d';}
.sir-icon-main:before{content:'\002e';}
.sir-icon-delivery-incoming:before{content:'\002f';}
.sir-icon-delivery-outgoing:before{content:'\005b';}
.sir-icon-timer:before{content:'\005c';}
.sir-icon-xls-download:before{content:'\005d';}
.sir-icon-csv-download:before{content:'\005e';}
.sir-icon-user-add:before{content:'\005f';}
.sir-icon-tick:before{content:'\0060';}
.sir-icon-sandclock:before{content:'\007b';}
.sir-icon-task:before{content:'\007c';}
.sir-icon-proposal:before{content:'\007d';}
.sir-icon-calendar:before{content:'\007e';}
.sir-icon-activity:before{content:'\003f';}